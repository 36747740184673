import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c17a286c = () => interopDefault(import('../pages/check-email.vue' /* webpackChunkName: "pages/check-email" */))
const _064133dc = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0ef65027 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _9de6c10c = () => interopDefault(import('../pages/medications/index.vue' /* webpackChunkName: "pages/medications/index" */))
const _f799d734 = () => interopDefault(import('../pages/password-updated.vue' /* webpackChunkName: "pages/password-updated" */))
const _d0b1d41e = () => interopDefault(import('../pages/paylinks/index.vue' /* webpackChunkName: "pages/paylinks/index" */))
const _2ba48e8f = () => interopDefault(import('../pages/prescriptions/index.vue' /* webpackChunkName: "pages/prescriptions/index" */))
const _c7f9f100 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _041c129f = () => interopDefault(import('../pages/registered.vue' /* webpackChunkName: "pages/registered" */))
const _44e3e6ba = () => interopDefault(import('../pages/resend-confirmation.vue' /* webpackChunkName: "pages/resend-confirmation" */))
const _28c08f76 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _21a6d500 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _51126a09 = () => interopDefault(import('../pages/settings/close-account.vue' /* webpackChunkName: "pages/settings/close-account" */))
const _7a437653 = () => interopDefault(import('../pages/settings/export.vue' /* webpackChunkName: "pages/settings/export" */))
const _2110c9a6 = () => interopDefault(import('../pages/settings/hospitals/index.vue' /* webpackChunkName: "pages/settings/hospitals/index" */))
const _3955510f = () => interopDefault(import('../pages/settings/options.vue' /* webpackChunkName: "pages/settings/options" */))
const _2cbf0530 = () => interopDefault(import('../pages/settings/pharmacies.vue' /* webpackChunkName: "pages/settings/pharmacies" */))
const _cd1463c2 = () => interopDefault(import('../pages/settings/security.vue' /* webpackChunkName: "pages/settings/security" */))
const _374a40aa = () => interopDefault(import('../pages/settings/user.vue' /* webpackChunkName: "pages/settings/user" */))
const _ef5b4c8a = () => interopDefault(import('../pages/settings/hospitals/new.vue' /* webpackChunkName: "pages/settings/hospitals/new" */))
const _5d855798 = () => interopDefault(import('../pages/settings/hospitals/_id/index.vue' /* webpackChunkName: "pages/settings/hospitals/_id/index" */))
const _1f3d0e98 = () => interopDefault(import('../pages/settings/hospitals/_id/edit.vue' /* webpackChunkName: "pages/settings/hospitals/_id/edit" */))
const _f2338cce = () => interopDefault(import('../pages/embed/rx.vue' /* webpackChunkName: "pages/embed/rx" */))
const _0eee84c6 = () => interopDefault(import('../pages/prescriptions/new.vue' /* webpackChunkName: "pages/prescriptions/new" */))
const _68242b6c = () => interopDefault(import('../pages/redirect/new.vue' /* webpackChunkName: "pages/redirect/new" */))
const _3c9990c5 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _d41f16ae = () => interopDefault(import('../pages/paylinks/_slug.vue' /* webpackChunkName: "pages/paylinks/_slug" */))
const _3006644c = () => interopDefault(import('../pages/prescriptions/_signature.vue' /* webpackChunkName: "pages/prescriptions/_signature" */))
const _27a645ae = () => interopDefault(import('../pages/new-password/_uid/_token.vue' /* webpackChunkName: "pages/new-password/_uid/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/check-email",
    component: _c17a286c,
    name: "check-email"
  }, {
    path: "/login",
    component: _064133dc,
    name: "login"
  }, {
    path: "/logout",
    component: _0ef65027,
    name: "logout"
  }, {
    path: "/medications",
    component: _9de6c10c,
    name: "medications"
  }, {
    path: "/password-updated",
    component: _f799d734,
    name: "password-updated"
  }, {
    path: "/paylinks",
    component: _d0b1d41e,
    name: "paylinks"
  }, {
    path: "/prescriptions",
    component: _2ba48e8f,
    name: "prescriptions"
  }, {
    path: "/register",
    component: _c7f9f100,
    name: "register"
  }, {
    path: "/registered",
    component: _041c129f,
    name: "registered"
  }, {
    path: "/resend-confirmation",
    component: _44e3e6ba,
    name: "resend-confirmation"
  }, {
    path: "/reset-password",
    component: _28c08f76,
    name: "reset-password"
  }, {
    path: "/settings",
    component: _21a6d500,
    name: "settings",
    children: [{
      path: "close-account",
      component: _51126a09,
      name: "settings-close-account"
    }, {
      path: "export",
      component: _7a437653,
      name: "settings-export"
    }, {
      path: "hospitals",
      component: _2110c9a6,
      name: "settings-hospitals"
    }, {
      path: "options",
      component: _3955510f,
      name: "settings-options"
    }, {
      path: "pharmacies",
      component: _2cbf0530,
      name: "settings-pharmacies"
    }, {
      path: "security",
      component: _cd1463c2,
      name: "settings-security"
    }, {
      path: "user",
      component: _374a40aa,
      name: "settings-user"
    }, {
      path: "hospitals/new",
      component: _ef5b4c8a,
      name: "settings-hospitals-new"
    }, {
      path: "hospitals/:id",
      component: _5d855798,
      name: "settings-hospitals-id"
    }, {
      path: "hospitals/:id/edit",
      component: _1f3d0e98,
      name: "settings-hospitals-id-edit"
    }]
  }, {
    path: "/embed/rx",
    component: _f2338cce,
    name: "embed-rx"
  }, {
    path: "/prescriptions/new",
    component: _0eee84c6,
    name: "prescriptions-new"
  }, {
    path: "/redirect/new",
    component: _68242b6c,
    name: "redirect-new"
  }, {
    path: "/",
    component: _3c9990c5,
    name: "index"
  }, {
    path: "/paylinks/:slug",
    component: _d41f16ae,
    name: "paylinks-slug"
  }, {
    path: "/prescriptions/:signature",
    component: _3006644c,
    name: "prescriptions-signature"
  }, {
    path: "/new-password/:uid?/:token?",
    component: _27a645ae,
    name: "new-password-uid-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
