import { render, staticRenderFns } from "./NavMenuNewRx.vue?vue&type=template&id=5f0bb77d&"
import script from "./NavMenuNewRx.vue?vue&type=script&lang=ts&"
export * from "./NavMenuNewRx.vue?vue&type=script&lang=ts&"
import style0 from "./NavMenuNewRx.vue?vue&type=style&index=0&id=5f0bb77d&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MaterialIcon: require('/tmp/build_07d1b0e6/components/MaterialIcon.vue').default,Button: require('/tmp/build_07d1b0e6/components/Button.vue').default})
